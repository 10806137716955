
/*****
APP
*****/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.flexbox-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* border-style: dotted; */
}

.main-flexbox-item {
  max-width:500px;
  /* border-style: solid; */
}
/*****
Main: NFTourisme.fr page.
*****/

.main-title {
  font-family: "Bookman Old Style";
  color: #16db93;
  size: 10px;
  text-align: center;
}

.main-headband {
  background-image: url('./images/dinard_headband.jpg');
  background-size:cover;
  height: 500px;
}

.main-bg {
  background-image: url('./images/bgbluegreenlight.jpg');
  background-size:cover;
}

/*****
City. Global to all cities
*****/

.city-title {
  font-family: "Bookman Old Style";
  color: #0076A8;
  text-align: center;
}

.city-link {
  font-family: "Bookman Old Style";
  color: #000000;
  font-size: 24px;
  text-align: end;
}

.city-bg {
  background-image: url('./images/bgbluegreenlight.jpg');
  background-size:cover;
}

@media (prefers-reduced-motion: no-preference) {
  .city-badge {
    animation: badge-spin infinite 20s linear;
    box-shadow: 1px 10px 10px rgb(97, 95, 95);
    border-radius: 50%;
  }
}

.city-flexbox-item {
  max-width:700px;
  /* border-style: solid; */
}

/*****
Specific city. Ex: NFTourisme.fr/lapagesecretedesaintmalo
*****/

.saintmalo-headband {
  background-image: url('./images/saintmalo_headband.jpg');
  background-size:cover;
  height: 500px;
}

.saintmalo-title {
  font-family: "Bookman Old Style";
  color: #bbdb9b;
  size: 10px;
  text-align: center;
}

.saintmalo-office-title {
  font-family: "Bookman Old Style";
  color: #1073b6;
  size: 10px;
  text-align: center;
}

@keyframes badge-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*******
Page du présentoir de Saint-malo
********/


/******
Debug
*******/

.show-border {
    border-style: solid;
  }
